<template>
<vs-row justify="center" class="posts" style="padding-top: 50px;" v-if="$parent.user != null">

    <vs-dialog prevent-close blur v-model="modalChooseProduct">
        <template #header>
            <h3 class="not-margin" style="font-weight: 100; margin-bottom: 0;">
                Configura il <b>tuo</b> prodotto.
            </h3>
        </template>

        <ProductList :products="products" />

    </vs-dialog>

    <vs-dialog prevent-close blur v-model="modalNewPost">

        <template #header>
            <h3 class="not-margin" style="font-weight: 100; margin-bottom: 0;">
                {{ newPost != null && newPost.id == null ? 'Crea' : 'Modifica' }} un <b>ricordo</b>.
            </h3>
        </template>
        <vs-row justify="flex-start" align="center" style="margin-bottom: 15px; margin-top: 0;" v-if="newPost != null">
            <vs-avatar history history-gradient circle v-if="$parent.user != null">
                <img :src="baseUrl + '/User/Media'" alt="" v-if="$parent.user.proPicFileId != null">
                <template #text v-if="$parent.user.proPicFileId == null">
                    {{ $parent.user.invoicingDetails.name }} {{ $parent.user.invoicingDetails.surname != null ? $parent.user.invoicingDetails.surname : ''}}
                </template>
            </vs-avatar>
            <input type="file" ref="imageInput" style="display: none;" accept="image/*" @change="fileInputChange" />
            <p style="margin-right: 2px; margin-left: 10px;"><b style="margin-right: 4px;">{{ $parent.user.invoicingDetails.name }}</b>
                <template v-if="(newPost.emozione != null && postTab != 'momenti') || postTab == 'emozioni'"> {{postTab == 'emozioni' && newPost.emozione == null ? '#...' : ''}} <b v-if="newPost.emozione != null">#{{newPost.emozione}}</b></template>

                <template v-else-if="(newPost.momento != null && postTab != 'emozioni') || postTab == 'momenti'">sta {{postTab == 'momenti' && newPost.momento == null ? '...' : ''}} <b v-if="newPost.momento != null">{{newPost.momento}}</b></template>
            </p>
            <!--<img v-if="newPost.emozione != null && postTab != 'momenti'" :src="getImgUrl(newPost.emozione)" style="margin-left: 10px; width: 32px; height: auto;" />-->
            <img v-if="newPost.momento != null && postTab != 'emozioni'" :src="getImgUrl(newPost.momento)" style="margin-left: 10px; width: 32px; height: auto;" />
        </vs-row>
        <div v-if="newPost != null">
            <transition name="slide-up" mode="out-in">
                <div class="con-form" key="newpost-textarea" v-if="postTab == 'main'">
                    <div style="display:grid;grid-auto-rows: 1fr;grid-row-gap: 5px;" :style="{'grid-template-columns': newPost.imageUrl != null ? '30% 70%' : '100%'}">
                        <div ref="imagePreview" v-show="newPost.imageUrl != null" class="image-box" style="border-top-left-radius: 10px; border-bottom-left-radius: 10px;" :style="{'background-image': 'url(' + newPost.imageUrl + ')'}"></div>
                        <textarea v-model="newPost.caption" ref="newPostInputReal" placeholder="A cosa stai pensando?" :class="{'with-image-preview': newPost.imageUrl != null}"></textarea>
                    </div>

                    <vs-row justify="space-between" style="margin-top: 15px; margin-bottom: 5px;">
                        <vs-button style="margin: 0; width: 50%;" transparent @click="$refs.imageInput.click()">
                            <i class='bx bxs-camera bx-sm' style="margin-right: 5px;"></i> Carica foto
                        </vs-button>
                        <vs-button style="margin: 0; width: 50%;" transparent @click="postTab = 'emozioni'">
                            <i class='bx bx-hash bx-sm' style="margin-right: 5px;"></i> Hashtag
                        </vs-button>
                        <!--<vs-button style="margin: 0; width: 33%;" transparent @click="postTab = 'momenti'">
                            <i class='bx bx-calendar-event bx-sm' style="margin-right: 5px;"></i> Momenti
                        </vs-button>-->

                    </vs-row>
                </div>
                <div class="con-form emozioni" key="newpost-emozioni" v-if="postTab == 'emozioni'" style="max-height: 152px; overflow-y: auto;">
                    <vs-row v-for="emozione in emozioni" :key="'emozione-'+emozione">
                        <vs-button size="large" style="margin: 0; padding: 0!important; color: black;" color="rgb(var(--vs-gray-2))" block transparent @click="selectEmotion(emozione)" :active="newPost.emozione == emozione">
                            <vs-row justify="center" align="center" style="gap: 8px;">
                                <!--<img :src="getImgUrl(emozione)" style="width: 35px;" />-->
                                <h4 style="margin-block-start: 1em; margin-block-end: 1em;">#{{emozione}}</h4>
                            </vs-row>
                        </vs-button>
                    </vs-row>
                </div>

                <div class="con-form emozioni" key="newpost-emozioni" v-if="postTab == 'momenti'" style="max-height: 152px; overflow-y: auto;">
                    <vs-row v-for="momento in momenti" :key="'momento-'+momento">
                        <vs-button size="large" style="margin: 0; padding: 0!important; color: black;" color="rgb(var(--vs-gray-2))" block transparent @click="selectMoment(momento)" :active="newPost.momento == momento">
                            <vs-row justify="space-between" align="center" style="gap: 8px; padding-left: 10px; padding-right: 10px; padding-top: 0.5em; padding-bottom: 0.5em;">
                                <div style="display: flex; align-items: center;">
                                    <img :src="getImgUrl(momento)" style="width: 35px; height: auto; margin-right: 6px;" />
                                    <h4 style="margin-block: 0;">{{momento}}</h4>
                                </div>
                                <i class='bx bxs-chevron-right bx-sm'></i>

                            </vs-row>
                        </vs-button>
                    </vs-row>
                </div>
            </transition>
        </div>

        <template #footer v-if="newPost != null">

            <vs-row style="margin-bottom: 5px;">
                <vs-button :loading="saving" v-if="newPost != null && newPost.id == null && postTab == 'main'" block size="large" style="margin: 0;" @click="patchPost">
                    Pubblica il tuo ricordo
                </vs-button>
                <vs-button :loading="saving" v-else-if="postTab == 'main'" block size="large" style="margin: 0;" @click="patchPost">
                    Salva modifiche
                </vs-button>
                <vs-button danger v-if="postTab == 'main' && newPost != null && newPost.id != null" block size="large" style="margin: 0; margin-top: 10px;" @click="deletePost">
                    Elimina post
                </vs-button>
                <vs-button block size="large" style="margin: 0;" v-if="postTab != 'main'" @click="postTab = 'main'">
                    {{(postTab == 'emozioni' && newPost.emozione != null) || (postTab == 'momenti' && newPost.momento != null) ? 'Conferma' : 'Indietro'}}
                </vs-button>

            </vs-row>
        </template>
    </vs-dialog>

    <vs-row justify="center" style="gap: 50px;">
        <vs-col lg="5" sm="5" xs="11" style="gap: 30px;">

            <vs-button size="large" block color="primary" @click="modalChooseProduct = true" style="margin-bottom: 20px; margin-left: 0; margin-right: 0;" class="animate mobile-only">
                <i class='bx bx-shopping-bag bx-sm'></i> Configura il tuo prodotto
            </vs-button>

            <!--
                NEW POST
            -->
            <vs-card  style="margin-bottom: 40px;">

                <template #text>
                    <vs-row  justify="space-between" style="padding-top: 15px;">
                        <vs-avatar history history-gradient circle v-if="$parent.user != null">
                            <img :src="baseUrl + '/User/Media'" alt="" v-if="$parent.user.proPicFileId != null">
                            <template #text v-if="$parent.user.proPicFileId == null">
                                {{ $parent.user.invoicingDetails.name }} {{ $parent.user.invoicingDetails.surname != null ? $parent.user.invoicingDetails.surname : ''}}
                            </template>
                        </vs-avatar>
                        <vs-input @click="showNewPostModal" class="new-post" style="flex-grow: 1; opacity: 1;" placeholder="Inizia a collezionare i tuoi ricordi..." />
                    </vs-row>

                </template>

            </vs-card>

            <!-- SORT AND FILTER -->
            <vs-row justify="space-between" align="center" style="margin-bottom: 20px;">
                <h2>I tuoi ricordi</h2>
                <vs-tooltip bottom shadow not-hover v-model="activeFilterTooltip" :loading="postLoading">
                    <vs-button icon @click="activeFilterTooltip = true">
                        <i class='bx bx-filter-alt bx-sm'></i> Filtra
                    </vs-button>
                    <template #tooltip>
                        <vs-row style="margin-top: 25px;">
                            <vs-input type="date" label="Dal" block v-model="postFilter.fromDate"></vs-input>
                        </vs-row>
                        <vs-row style="margin-top: 25px;">
                            <vs-input type="date" label="Al" block v-model="postFilter.toDate"></vs-input>
                        </vs-row>

                        <vs-row>
                            <vs-button block transparent v-if="postFilter.sort == 'DESC'" @click="postFilter.sort = 'ASC'"><i class='bx bx-sort-up bx-sm'></i> Decrescente</vs-button>
                            <vs-button block transparent v-if="postFilter.sort == 'ASC'" @click="postFilter.sort = 'DESC'"><i class='bx bx-sort-down bx-sm'></i> Crescente</vs-button>
                        </vs-row>
                        <vs-row>
                            <vs-button block @click="resetFilters"><i class='bx bx-eraser bx-sm'></i> Pulisci filtri</vs-button>
                        </vs-row>
                    </template>
                </vs-tooltip>

            </vs-row>

            <Post @click.native="editPost(p)" v-for="(p,k) in posts" :key="'post-' + k" :title="(p.emozione != null ? ('#' + p.emozione) : p.momento != null ? ('Sta' + p.momento) : '')" :caption="p.caption" :emoji="(p.momento != null ? p.momento : null)" :image="p.imageUrl" :created="p.createdAt" />
            <!-- EXAMPLE POST BEFORE LOGIN ONLY -->
            <Post v-if="$parent.user == null" title="Sta festeggiando il compleanno di Matteo" caption="Festeggiando, insieme ai miei due figli, il 4° compleanno di Matteo." emoji="festeggiando" image="examples/examplePost.jpg" />

        </vs-col>
        <vs-col w="3" class="products-list-container">
            <ProductList :products="products" />
        </vs-col>
    </vs-row>

</vs-row>
</template>

<script>
import ProductList from '../components/ProductList';
import Post from '../components/Post';
import {
    apiCall,
    baseUrl
} from '../client';
import {
    s3Client
} from '../s3Client';

export default ({
    components: {
        Post,
        ProductList
    },
    setup() {

    },

    data() {
        return {
            modalNewPost: false,
            postTab: 'main',
            //emozioni: ['arrabbiato', 'assonnato', 'demoralizzato', 'distrutto', 'divertito', 'felice', 'fiero', 'fortunato', 'infreddolito', 'ingolosito', 'innamorato', 'malato', 'pensieroso', 'raffreddato', 'sconsolato', 'stufo', 'stupito', 'triste'],
            emozioni: ['relax', 'family', 'friends', 'traguardi', 'divertimento', 'festa', 'vacanza', 'sport', 'shopping', 'orgoglioso', 'lifestyle', 'triste', 'innamorato', 'felice', 'stanco', 'pensieroso'],
            momenti: ['bevendo', 'festeggiando', 'giocando', 'guardando', 'mangiando', 'viaggiando'],
            newPost: {
                emozione: null,
                momento: null,
                caption: '',
                imageUrl: null,
                fileContent: null
            },

            posts: [],

            baseUrl,

            activeFilterTooltip: false,

            postFilter: {
                sort: 'DESC',
                fromDate: null,
                toDate: null,
            },

            postLoading: false,
            postFinished: true, // ultima pagina dei post, non devo più continuare a caricarne altri, inizialmente true, dopo il primo caricamento dei post = false
            currentPage: 0,
            products: [],

            saving: false,

            modalChooseProduct: false
        }
    },

    methods: {
        validatePost(){
            // the post must have at least a caption or an image
            if(this.newPost.caption == '' && (this.newPost.fileContent == null && this.newPost.fileId == null)){
                // show an error
                this.$vs.notification({
                    icon: '<i class="bx bx-error"></i>',
                    color: 'danger',
                    position: 'top-right',
                    title: 'Ops!',
                    text: `Il post deve avere almeno una foto o una descrizione.`
                })
                return false;
            }
            return true;
        },
        editPost(e) {
            this.newPost = JSON.parse(JSON.stringify(e)); // deep copy
            this.newPost.fileContent = null; // The photo would be the same
            this.modalNewPost = true;

        },

        async getProducts() {
            var result = await apiCall('GET', '/Product');
            if (result.status == 200) {
                this.products = result.data;
            }
        },
        async putPost() { //draft
            this.saving = true;
            var result = await apiCall('PUT', '/Post', {
                Id: '',
                UserId: 0,
                Emotion: null,
                Text: null,
                User: {}
            });
            this.saving = false;
            if (result.status == 200) {
                this.newPost.id = result.data.id;
                this.newPost.fileId = result.data.fileId;
                this.modalNewPost = true;
            } else {
                this.$vs.notification({
                    icon: '<i class="bx bx-error"></i>',
                    color: 'danger',
                    position: 'top-right',
                    title: 'Ops!',
                    text: `Impossibile creare il post.`
                })
            }

        },

        async deletePost() {
            var result = await apiCall('DELETE', '/Post', {
                Id: this.newPost.id
            });
            if (result.status == 200) {
                this.posts = this.posts.filter(e => e.id != this.newPost.id);
                this.modalNewPost = false;
            } else {
                this.$vs.notification({
                    icon: '<i class="bx bx-error"></i>',
                    color: 'danger',
                    position: 'top-right',
                    title: 'Ops!',
                    text: `Impossibile eliminare il post.`
                })
            }

        },

        async patchPost() {
            if(!this.validatePost()){
                return;
            }
            this.saving = true;
            var result = await apiCall('PATCH', '/Post', {
                Id: this.newPost.id,
                UserId: 0,
                Emotion: this.newPost.emozione != null ? this.newPost.emozione : (this.newPost.momento != null ? this.newPost.momento : null),
                Text: this.newPost.caption,
                User: {}
            });
            this.saving = false;
            if (result.status == 200) {
                var p = this.posts.find(e => e.id == this.newPost.id);
                if (p != null) {
                    p.emozione = this.newPost.emozione;
                    p.momento = this.newPost.momento;
                    p.caption = this.newPost.caption;
                    p.fileId = this.newPost.fileId;
                    p.imageUrl = this.newPost.imageUrl;
                } else {
                    this.posts.unshift({
                        id: result.data.id,
                        emozione: result.data.emotion != null ? (this.emozioni.includes(result.data.emotion) ? result.data.emotion : null) : null,
                        momento: result.data.emotion != null ? (this.momenti.includes(result.data.emotion) ? result.data.emotion : null) : null,
                        caption: result.data.text,
                        imageUrl: this.newPost.imageUrl,
                        createdAt: new Date(result.data.createdAt),
                        fileId: result.data.fileId
                    });
                }
                this.modalNewPost = false;
            } else {
                this.$vs.notification({
                    icon: '<i class="bx bx-error"></i>',
                    color: 'danger',
                    position: 'top-right',
                    title: 'Ops!',
                    text: `Impossibile modificare il post.`
                })
            }

        },

        async fileInputChange() {
            var vm = this;
            const file = this.$refs.imageInput.files[0];
            if (file) {
                if(file.size > 1024 * 1024 * 10){
                    this.$vs.notification({
                        icon: '<i class="bx bx-error"></i>',
                        color: 'danger',
                        position: 'top-right',
                        title: 'Ops!',
                        text: `La foto non può essere più grande di 10MB.`
                    })
                    return;
                }
                const loading = this.$vs.loading({
                    text: 'Caricamento in corso...'
                })
                try {
                    const result = await s3Client.uploadFile(file, this.newPost.fileId);
                    if(result.status == 200){
                        // get signed download url
                        const signedUrl = await s3Client.getSignedDownloadURL({name: this.newPost.fileId});
                        this.newPost.imageUrl = signedUrl.url;
                        this.newPost.__ob__.dep.notify();
                        console.log(this.newPost);
                    } else {
                        throw new Error('Error uploading file');
                    }
                } catch (error) {
                    console.error(error);
                    this.$vs.notification({
                        icon: '<i class="bx bx-error"></i>',
                        color: 'danger',
                        position: 'top-right',
                        title: 'Ops!',
                        text: `Impossibile caricare la foto.`
                    })
                }

                loading.close();
            }
        },

        selectEmotion(emozione) {
            if (this.newPost.emozione == emozione) {
                this.newPost.emozione = null;
            } else {
                this.newPost.emozione = emozione;
                this.newPost.momento = null;
            }

        },

        selectMoment(momento) {
            if (this.newPost.momento == momento) {
                this.newPost.momento = null;
            } else {
                this.newPost.momento = momento;
                this.newPost.emozione = null;
            }

        },

        showNewPostModal(e) {
            this.putPost();
            // blur this input
            e.target.blur();
        },

        getImgUrl(pet) {
            var images = require.context('../assets/emoji/', false, /\.png$/)
            return images('./' + pet + ".png")
        },

        async getPosts(concat = false) {
            var vm = this;
            this.postLoading = true;
            var p = JSON.parse(JSON.stringify(this.postFilter)); // deep copy
            p.page = this.currentPage;
            var result = await apiCall('POST', '/Post', p);
            this.postLoading = false;
            if (result.status == 200) {
                if (result.data.posts.length > 0) {
                    this.postFinished = false;
                } else {
                    this.postFinished = true;
                }
                var newPosts = result.data.posts.map(function (e) {
                    return {
                        id: e.id,
                        emozione: e.emotion != null ? (vm.emozioni.includes(e.emotion) ? e.emotion : null) : null,
                        momento: e.emotion != null ? (vm.momenti.includes(e.emotion) ? e.emotion : null) : null,
                        caption: e.text,
                        imageUrl: null,
                        createdAt: new Date(e.createdAt),
                        fileId: e.fileId
                    }
                });

                await Promise.all(newPosts.map(async function (e) {
                    if (e.fileId != null) {
                        const signedUrl = await s3Client.getSignedDownloadURL({name: e.fileId});
                        const exists = await s3Client.fileExists({url: signedUrl.url});
                        if(exists){
                            e.imageUrl = signedUrl.url;
                        }
                    }
                }));

                if (concat) {
                    this.posts = this.posts.concat(newPosts);
                } else {
                    this.posts = newPosts;
                }
            }
        },

        resetFilters() {
            this.postFilter = {
                sort: 'DESC',
                fromDate: null,
                toDate: null,
            }
        }
    },

    watch: {
        modalNewPost(n) {
            if (n) {
                this.postTab = 'main';
                if (this.newPost == null) {
                    this.newPost = {
                        emozione: null,
                        momento: null,
                        caption: '',
                        fileContent: null
                    }
                }

            } else {
                this.newPost = null;
            }
        },

        '$parent.user'(n) {
            if (n != null) {
                this.getPosts();
                this.getProducts();
            }
        },

        postFilter: {
            deep: true,
            handler: function () {
                if (this.currentPage > 0) { // se era a una pagina maggiore di zero la imposto a 0, e questo in automatico ritriggera il caricamento del feed
                    this.currentPage = 0;
                } else { // se invece era già a 0, ricarico il feed manualmente
                    this.getPosts(false);
                }

            }
        },

        currentPage(n) {
            this.getPosts(n > 0); // se non è la prima pagina concateno i post a quelli precedenti
        }
    },

    async mounted() {
        var vm = this;
        this.fromDate = new Date();
        this.fromDate.setMonth(this.fromDate.getMonth()-3);
        this.fromDate = this.fromDate.toISOString().split('T')[0];
        this.toDate = (new Date()).toISOString().split('T')[0];
        if (this.$parent.user != null) {
            const loading = this.$vs.loading({
                text: 'Caricamento in corso...'
            })
            
            await this.getPosts();
            await this.getProducts();
            loading.close();
        }

        window.onscroll = () => {
            let bottomOfWindow = document.documentElement.scrollTop + window.innerHeight === document.documentElement.offsetHeight;

            if (bottomOfWindow) {
                if (!vm.postFinished && !vm.postLoading) { // non ho finito le pagine e non sta caricando
                    vm.currentPage++;
                }
            }
        };
    }
})
</script>

<style>

.emozioni .vs-button--transparent::before {
    background: rgb(var(--vs-gray-4)) !important;
}

.emozioni .vs-button--transparent.vs-button--active::before {
    background: rgba(var(--vs-primary), 0.25) !important;
}

.emozioni {
    background: rgb(var(--vs-gray-3));
    border-radius: 10px;
}

.emozioni .vs-button__content {
    padding: 0 !important;
}

.posts .vs-card {
    max-width: unset !important;
}

.type-3 .vs-card__img {
    max-width: 60% !important;
}

.posts .vs-avatar-content {
    width: 50px !important;
    height: 50px !important;

    margin-right: 10px;
}

.new-post input::placeholder {
    font-size: 18px !important;
}

.vs-dialog {
    width: 500px;
}

.image-box {
    padding-bottom: 100%;
    position: relative;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
}

.image-box::before,
.image-box::after {
    width: 100%;
    height: 100%;
    content: "";
    position: absolute;
}

.image-box::before {
    backdrop-filter: blur(10px) brightness(0.8);
}

.image-box::after {
    background-image: inherit;
    background-size: contain;
    background-position: inherit;
    background-repeat: inherit;
}

.with-image-preview {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.posts .type-1 .vs-card__img {
    max-height: unset !important;
}

.vs-tooltip .vs-input {
    background-color: #e7e7e7!important;
}

.products-list .vs-card__img {
    max-width: 40%!important;
    min-width: 40%!important;
}


@media (max-width: 768px) {
    /* remove padding-top 50px from mobile to .posts*/
    .posts {
        padding-top: 0px!important;
    }
}

</style>
