<template>
    <div class="products-list">
        <vs-card type="3" style="width: 100%;" v-for="p in products" :key="'product-'+p.id">
            <template #title>
                <h3>{{ p.title }}</h3>
            </template>
            <template #img>
                <img class="product-image" :src="p.previewFileUrl || placeholder" :alt="p.title">
            </template>
            <template #text>
                <vs-row justify="space-between" diretion="column" style="height: 100%;">
                    <p>
                        {{ p.description }}
                    </p>
                    <vs-button style="margin:0;" animation-type="scale" block @click="$router.push({name: 'store', params: {productId: p.id}})">
                        <i class='bx bx-right-arrow-alt bx-sm'></i>
                        <template #animate>
                            Configura ora
                        </template>
                    </vs-button>
                </vs-row>

            </template>

        </vs-card>

    </div>
</template>
<script>
import placeholder from '@/assets/placeholder.png';
export default {
    name: 'ProductList',
    props: {
        products: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            placeholder: placeholder
        }
    }
}
</script>
<style scoped>
.products-list .vs-card-content.type-3 {
    margin-bottom: 20px;
}

.products-list .vs-card__img {
    min-height: 230px;
}

.products-list p {
    text-overflow: ellipsis;
    word-wrap: break-word;
    overflow: hidden;
    max-height: 4.5em;
    line-height: 1.5em;
}

.product-image {
    min-height: 200px;
}

</style>